<template>
  <div :class="{ white : !isDesktop }" class="pt-4 pb-2">
    <router-link
      :to="$store.state.auth.currentUser.role === 'account_admin' && $route.path === '/new-member' ? '/members' : '/contacts'"
      :class="{ 'fz-20 black--text mb-5' : !isDesktop }"
      class="back-link px-3 px-md-0 ml-md-n2"
    >
      <v-icon size="24px" :color="!isDesktop ? '#6170C5' : ''">navigate_before</v-icon>
      {{ $store.state.auth.currentUser.role === 'account_admin' && $route.path === '/new-member' ? (isDesktop ? 'Members' : 'New Member') :
      (isDesktop ? 'Contacts' : 'New Contact') }}
    </router-link>

    <h1 v-if="isDesktop" class="dark-text font-weight-semi-bold fz-28 mb-4 px-5 px-md-0">{{ $route.path === '/new-member' ? 'New Member' : 'New Contact'}}</h1>

    <v-card class="px-4 mb-5" :style="isDesktop ? 'padding-block:25px' : 'box-shadow:none !important'">
      <v-form ref="newContact" v-model="isValid" lazy-validation>
        <v-row class="form-row">
          <v-col
            cols="12"
            md="6"
            class="pb-0 new-contact__field"
          >
            <label
              class="fz-14 mb-1 d-block required"
            >First name</label>
            <v-text-field
              v-model.trim="form.firstName"
              :rules="firstNameRules"
              :maxlength="16"
              class="text-input"
              solo
              outlined
              label="First name"
              :height="isDesktop ? '36px' : '48px'"
              type="text"
              dense
              @keypress="onlyAlphanumeric"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="pb-0 new-contact__field"
          >
            <label
              :class="{ required: $route.path === '/new-member' || !form.firstName || form.lastName }"
              class="fz-14 mb-1 d-block"
            >Last name</label>
            <v-text-field
              v-model="form.lastName"
              :rules="lastNameRules"
              :maxlength="16"
              class="text-input"
              solo
              outlined
              label="Last name"
              :height="isDesktop ? '36px' : '48px'"
              type="text"
              dense
              @keypress="onlyAlphanumeric"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0 new-contact__field"
          >
            <label :class="{ required: $route.path === '/new-member' }" class="fz-14 mb-1 d-block">Email</label>
            <v-text-field
              autocomplete="new-email"
              solo
              outlined
              label="Email"
              :height="isDesktop ? '36px' : '48px'"
              type="email"
              v-model="form.email"
              :rules="$route.path === '/new-member' || form.email ? emailRulesDynamic : []"
              dense
              @blur="validate"
              @keydown.enter="validate"
              @focus="clearRules"
              @input="clearRules"
              @keypress="disableSpace"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0 new-contact__field"
          >
            <label :class="{ required: $route.path === '/new-contact' }" class="fz-14 mb-1 d-block">Phone number</label>
            <v-text-field
              v-model="phoneNumber"
              :rules="$route.path === '/new-member' ? (form.phone ? phoneRulesMember : []) : phoneRules"
              :maxlength="hasCountryCode ? 17 : 14"
              solo
              outlined
              label="Phone number"
              :height="isDesktop ? '36px' : '48px'"
              type="tel"
              dense
              @keypress="IsNumber($event)"
              @paste.prevent="onPasteNumber"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="$route.path !== '/new-contact' && !$route.params.number"
            cols="12"
            md="6"
            class="py-0 new-member__field"
          >
            <label class="fz-14 mb-1 d-block required">New password</label>
            <v-text-field
              v-model="form.password"
              :rules="passwordRules"
              :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPass ? 'text' : 'password'"
              :height="isDesktop ? '36px' : '48px'"
              autocomplete="new-password"
              solo
              class="mb-n2"
              outlined
              label="New password"
              dense
              @click:append="showNewPass = !showNewPass"
            >
            </v-text-field>
          </v-col>

          <v-col
            v-if="$route.path !== '/new-contact' && !$route.params.number"
            cols="12"
            md="6"
            class="py-0 new-member__field"
          >
            <label class="fz-14 mb-1 d-block required">Confirm password</label>
            <v-text-field
              v-model="form.rePassword"
              :rules="rePasswordRules"
              :append-icon="showRePass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showRePass ? 'text' : 'password'"
              :height="isDesktop ? '36px' : '48px'"
              ref="rePassword"
              solo
              outlined
              class="mb-n2"
              label="Confirm password"
              dense
              @click:append="showRePass = !showRePass"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="pt-2 new-member__field"
            v-if="$route.path === '/new-member'"
          >
            <label class="fz-14 mb-1 d-block required">Role</label>
            <v-select
              v-model="form.selectRole"
              :items="role"
              :rules="roleRules"
              :height="isDesktop ? '36px' : '48px'"
              solo
              outlined
              item-text="name"
              label="Select role"
              menu-props="offsetY"
              hide-details
              class="pa-0"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>
<!--          <v-col-->
<!--            v-if="$route.path !== '/new-contact' && form.selectRole !== 'user'"-->
<!--            cols="12"-->
<!--            md="6"-->
<!--            class="py-0"-->
<!--          >-->
<!--            <label class="fz-14 mb-1 d-block">Company Name</label>-->
<!--            <v-text-field-->
<!--              solo-->
<!--              outlined-->
<!--              class="mb-n2"-->
<!--              label="Company Name"-->
<!--              type="text"-->
<!--              v-model="form.companyName"-->
<!--              dense-->
<!--            ></v-text-field>-->
<!--          </v-col>-->
<!--          <v-col-->
<!--            v-if="$route.path === '/new-contact'"-->
<!--            cols="12"-->
<!--            md="6"-->
<!--            class="py-0 d-flex align-center mb-3">-->
<!--            <label class="fz-14 mr-3 d-block">Active</label>-->
<!--            <v-switch-->
<!--              class="mt-0"-->
<!--              v-model="form.isActive"-->
<!--              inset-->
<!--              hide-details-->
<!--            ></v-switch>-->
<!--          </v-col>-->
          <!--          <v-col-->
          <!--            v-if="$route.path !== '/new-member'"-->
          <!--            cols="12"-->
          <!--            md="6"-->
          <!--            class="pt-0"-->
          <!--          >-->
          <!--            <label class="fz-14 mb-1 d-block">Contact Type</label>-->
          <!--            <v-select-->
          <!--              style="width: 100%"-->
          <!--              v-model="contactType"-->
          <!--              :items="contactTypes"-->
          <!--              menu-props="offsetY"-->
          <!--              dense-->
          <!--              flat-->
          <!--              outlined-->
          <!--              full-width-->
          <!--              solo-->
          <!--              hide-details-->
          <!--              placeholder="Select"-->
          <!--            />-->
          <!--          </v-col>-->
        </v-row>
        <footer-buttons
          v-if="!isDesktop"
          :disabled="disabled"
          ok="Save"
          @ok="$route.path === '/new-member' ? addMember() : createContact()"
        />
      </v-form>
      <vue-snotify/>
    </v-card>
    <header-buttons
      v-if="isDesktop"
      :changed="changed"
      :disabled="disabled"
      ok="Create"
      @ok="$route.path === '/new-member' ? addMember() : createContact()"
    />
  </div>
</template>

<script>
import HeaderButtons from '@/components/HeaderButtons'
import FooterButtons from '@/components/FooterButtons'
import { mapActions } from 'vuex'

export default {
  name: 'NewContact',
  components: { HeaderButtons, FooterButtons },
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    changed: false,
    contactTypes: [
      'Company staff',
      'Outside contacts'
    ],
    contactType: undefined,
    role: [
      {
        name: 'Company Admin',
        value: 'system_admin'
      },
      {
        name: 'Company User',
        value: 'account_user'
      }
    ],
    form: {
      firstName: '',
      lastName: '',
      password: '',
      rePassword: '',
      email: '',
      phone: '',
      selectRole: ''
    },
    firstNameRuleMax: [
      v => v.length <= 16 || 'First name must be less than 16 characters',
      v => /^[a-zA-Z0-9 ]+$/.test(v) || 'First name must not contain special characters'
    ],
    firstNameRuleReq: [
      v => !!v || 'First name is required'
    ],
    lastNameRuleReq: [
      v => !!v || 'Last name is required'
    ],
    lastNameRuleMax: [
      v => v.length <= 16 || 'Last name must be less than 16 characters',
      v => /^[a-zA-Z0-9 ]+$/.test(v) || 'Last name must not contain special characters'
    ],
    roleRules: [
      v => !!v || 'Role is required'
    ],
    emailRulesDynamic: [],
    disabledButton: false,
    showNewPass: false,
    showRePass: false,
    emailExist: false,
    isValid: false
  }),
  computed: {
    passwordRules () {
      return [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'Password must be at least 8 characters',
        v => v.length <= 20 || 'Password must be less than 20 characters',
        v => /(?=.*[A-Z0-9!@#$%^&.*])/.test(v) || 'Password must contain at least least one upper case, numeric, and special character',
        v => /(?=.*[0-9!@#$%^&.*])/.test(v) || 'Password must contain at least one numeric and special character',
        v => /(?=.*[A-Z!@#$%^&.*])/.test(v) || 'Password must contain at least one uppercase and special character',
        v => /(?=.*[!@#$%^&.*])/.test(v) || 'Password must contain at least one special character',
        v => /(?=.*[0-9])/.test(v) || 'Password must contain at least one numeric character',
        v => /(?=.*?[A-Z])/.test(v) || 'Password must contain at least one uppercase alphabetical character',
        v => /(?=.*?[a-z])/.test(v) || 'Password must contain at least one lowercase alphabetical character',
        v => v === this.form.password || 'Passwords does not match'
      ]
    },
    rePasswordRules () {
      return [
        v => v === this.form.password || 'Passwords should be the same'
      ]
    },
    emailRules () {
      return [
        v => !!v || 'E-mail is required',
        v => (!!v && !this.emailExist) || 'E-mail is already exixst',
        // eslint-disable-next-line no-useless-escape
        v => /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'Wrong format'
      ]
    },
    firstNameRules () {
      return this.form.firstName
        ? this.firstNameRuleMax.concat(this.firstNameRuleReq)
        : this.firstNameRuleReq
    },
    lastNameRules () {
      if (this.$route.path === '/new-member') {
        return this.form.lastName
          ? this.lastNameRuleMax.concat(this.lastNameRuleReq)
          : this.lastNameRuleReq
      }
      return this.form.firstName && this.form.lastName
        ? this.lastNameRuleMax
        : this.form.lastName && !this.form.firstName
          ? this.lastNameRuleMax.concat(this.lastNameRuleReq)
          : []
    },
    phoneRulesMember () {
      return [
        () => ((this.hasCountryCode && this.form.phone.length >= 11) ||
          (!this.hasCountryCode && this.form.phone.length >= 10)) ||
          `Phone number must be at least ${this.hasCountryCode ? '11' : '10'} characters`,
        v => /^[0-9]+$/.test(v.replace(/\D/g, '')) || 'Phone number must be valid'
      ]
    },
    phoneRules () {
      return [
        v => !!v || 'Phone number is required',
        ...this.phoneRulesMember
      ]
    },
    disabled () {
      const check = ['password', 'rePassword', 'email', 'selectRole']
      return ((!this.form.firstName.trim() && !this.form.lastName.trim()) ||
        ((this.$route.path === '/new-contact' && (this.form.phone.length < 10 ||
          (this.form.phone[0] === '1' && this.form.phone.length < 11))) ||
        (this.$route.path === '/new-member' && check.some(e => !this.form[e].trim())))) ||
        this.disabledButton || !this.isValid
    },
    phoneNumber: {
      get () {
        if (!this.form.phone) return ''
        try {
          const cleaned = `${this.form.phone}`
          const match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/)
          return [
            match[1] ? '+1 ' : '',
            match[2] ? '(' : '',
            match[2],
            match[3] ? ') ' : '',
            match[3],
            match[4] ? '-' : '',
            match[4]
          ].join('')
        } catch (err) {
          return `(${this.form.phone.slice(0, 3)}) ${this.form.phone.slice(3, 6)}-${this.form.phone.slice(6)}`
        }
      },
      set (e) {
        this.form.phone = e.replace(/\D/g, '')
      }
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    hasCountryCode () {
      return this.form.phone.length && this.form.phone[0] === '1'
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.changed = true
      }
    },
    'form.email' (val) {
      if (!val) return
      this.emailExist = false
    },
    'form.password' () {
      this.$refs.rePassword.validate()
    }
  },
  created () {
    if (this.$route.params.number) {
      this.form.phone = this.$route.params.number
    }
    if (this.$route.params.firstName) {
      this.form.firstName = this.$route.params.firstName
    }
    if (this.$route.params.lastName) {
      this.form.lastName = this.$route.params.lastName
    }
  },
  methods: {
    ...mapActions(['createMember']),
    async addMember () {
      if (!this.$refs.newContact.validate()) return
      this.disabledButton = true
      try {
        const formData = new FormData()
        formData.append('email', this.form.email)
        formData.append('password', this.form.password)
        formData.append('rePassword', this.form.rePassword)
        formData.append('firstName', this.form.firstName)
        formData.append('lastName', this.form.lastName)
        if (this.form.phone) formData.append('userPhoneNumber', this.form.phone)

        const { data } = await this.createMember({ selectRole: this.form.selectRole, formData })

        // console.log('data', data)
        this.$snotify.notifications = []
        this.$snotify.success('Member has been added')
        setTimeout(e => {
          this.$router.push({ path: '/members' })
        }, 2000)
      } catch (err) {
        this.$snotify.error(err.message)
        if (+err.statusCode === 409) {
          this.emailExist = true
          this.$refs.newContact.validate()
        }
        // this.form = {}
        throw new Error(err)
      } finally {
        this.disabledButton = false
      }
    },
    createContact () {
      if (!this.$refs.newContact.validate()) return
      if (!this.form.firstName && !this.form.lastName) {
        this.$snotify.notifications = []
        this.$snotify.error('First or last name required')
        return
      }
      this.disabledButton = true

      const payload = {
        phoneNumber: this.form.phone
      }
      if (this.form.firstName) payload.firstName = this.form.firstName
      if (this.form.lastName) payload.lastName = this.form.lastName
      if (this.form.email) payload.email = this.form.email

      this.$store
        .dispatch('createContact', payload)
        .then((res) => {
          console.log('res', res)
          this.$snotify.notifications = []
          this.$snotify.success('Contact has been added')
          setTimeout(e => {
            this.$router.push({ name: 'Contacts' })
          }, 2000)
        }).catch(err => {
          this.$snotify.notifications = []
          this.$snotify.error(err.message)
          // this.form = {}
          console.log('err', err)
        }).finally(() => {
          this.disabledButton = false
        })
    },
    validate () {
      this.setRules()
      this.compute()
    },
    setRules () {
      this.emailRulesDynamic = this.emailRules
    },
    compute () {
      try {
        this.$emit('input', this.form.email)
      } catch (e) {
        console.log('compute error', e)
      }
    },
    clearRules () {
      this.emailRulesDynamic = []
    },
    onPasteNumber (evt) {
      const filter = evt.clipboardData.getData('text').replace(/\D/g, '')
      if (!filter) return
      this.phoneNumber = filter
    },
    disableSpace (event) {
      if (event.key === ' ') return event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  color: gray !important;
}

.form-row {
  padding-inline: 2px;
}

.text-input ::v-deep .v-text-field__details {
  display: none;
}

@media screen and (max-width: 375px) {
  .form-row {
    justify-content: center;
    margin: 0;

    .new-contact__field, .new-member__field {
      padding-left: 0;
      padding-right: 0;

      max-width: 342px;
    }
  }
}
</style>
